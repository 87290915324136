module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/codebuild/output/src807903991/src/joshuachongrocks/node_modules/gatsby-remark-images","id":"98a089e7-b43a-59d2-bb38-64fe6ef8bdf2","name":"gatsby-remark-images","version":"3.3.21","pluginOptions":{"plugins":[],"maxWidth":820,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/codebuild/output/src807903991/src/joshuachongrocks/node_modules/gatsby-remark-responsive-iframe","id":"0c95c0d4-3c04-54d4-8e39-232b660f7c94","name":"gatsby-remark-responsive-iframe","version":"2.4.11","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"remarkPlugins":[null,null],"defaultLayouts":{"pages":"/codebuild/output/src807903991/src/joshuachongrocks/node_modules/gatsby-theme-chronoblog/src/components/page/index.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":820,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"}},"gatsby-remark-responsive-iframe"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":820,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Joshua Chong personal site","short_name":"Joshua Chong","start_url":"/","background_color":"#fff","theme_color":"#3a5f7d","display":"standalone","icon":"src/assets/favicon.png","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b433f96adabf364e2e3b1d77c0f6ddb9"},
    }]
